import styled from "@emotion/styled";
import { buttonReset } from "../styles/styles";

export const Button = styled.button`
  ${buttonReset};
  padding: 16px;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.text.light};
  background-color: ${(props) => props.theme.colors.button.primary};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LinkButton = styled.a`
  ${buttonReset};
  padding: 10px 18px;
  border-radius: 12px;
  color: ${(props) => props.theme.colors.text.light};
  background-color: ${(props) => props.theme.colors.button.primary};
  text-decoration: none;
`;
