import { useEffect, useState } from "react";
import { screenSize } from "../styles/theme";
import { useWindowSize } from "./useWindowSize";

type DeviceSize = "s" | "m" | "l";

export const useDeviceSize = (): DeviceSize | undefined => {
  const windowSize = useWindowSize();
  const [deviceType, setDeviceSize] = useState<DeviceSize | undefined>(
    undefined
  );

  useEffect(() => {
    if (windowSize.width) {
      if (windowSize.width < screenSize.m) {
        setDeviceSize("s");
      } else if (
        windowSize.width >= screenSize.m &&
        windowSize.width < screenSize.l
      ) {
        setDeviceSize("m");
      } else {
        setDeviceSize("l");
      }
    }
  }, [windowSize]);

  return deviceType;
};
