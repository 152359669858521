import styled from "@emotion/styled";

type JustifyContent =
  | "center"
  | "start"
  | "end"
  | "flex-start"
  | "flex-end"
  | "left"
  | "right"
  | "normal"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "stretch"
  | "safe center"
  | "unsafe center"
  | "inherit"
  | "initial"
  | "revert"
  | "unset";

type AlignItems =
  | "normal"
  | "stretch"
  | "center"
  | "start"
  | "end"
  | "flex-start"
  | "flex-end"
  | "baseline"
  | "first baseline"
  | "last baseline"
  | "safe center"
  | "unsafe center"
  | "inherit"
  | "initial"
  | "revert"
  | "unset";

type FlexProps = {
  direction?: "row" | "column";
  flex?: number;
  justify?: JustifyContent;
  align?: AlignItems;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  flex: ${(props) => props.flex || 0};
  justify-content: ${(props) => props.justify || "normal"};
  align-items: ${(props) => props.align || "normal"};
`;

export const Row = styled(Flex)<FlexProps>`
  flex-direction: row;
`;

export const Column = styled(Flex)<FlexProps>`
  flex-direction: column;
`;
