import styled from "@emotion/styled";
import { useRef } from "react";
import { Calculator } from "./components/Calculator";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { HowItWorks } from "./components/HowItWorks";
import { Info } from "./components/Info";
import { SignUp } from "./components/SignUp";

export const Home = () => {
  const calculatorRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const signUpRef = useRef<HTMLDivElement>(null);

  return (
    <StyledHome>
      <Hero
        onHowMuchCanISaveClick={() => calculatorRef.current?.scrollIntoView()}
        onHowItWorksClick={() => howItWorksRef.current?.scrollIntoView()}
      />
      <Calculator
        calculatorRef={calculatorRef}
        onSignUpClick={() => signUpRef.current?.scrollIntoView()}
      />
      <HowItWorks howItWorksRef={howItWorksRef} />
      <Info />
      <SignUp signUpRef={signUpRef} />
      <Footer />
    </StyledHome>
  );
};

const StyledHome = styled.div`
  margin: 0px auto;
`;
