/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import { FC, MutableRefObject, useState } from "react";
import { ErrorAlert } from "../../../components/Alerts";
import { Button } from "../../../components/Buttons";
import { ContentContainer } from "../../../components/Container";
import { Row } from "../../../components/Flex";
import { Spacer } from "../../../components/Spacer";
import { borgeApi } from "../../../utils/api";
import { UnionType } from "../../../utils/unionType";
import { validateEmail } from "../../../utils/validate";
import confetti from "./assets/confetti.svg";

type RegisterState = UnionType<null> | { type: "INIT" };

export const SignUp: FC<{
  signUpRef: MutableRefObject<HTMLDivElement | null>;
}> = ({ signUpRef }) => {
  const [email, setEmail] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  const [registerState, setRegisterState] = useState<RegisterState>({
    type: "INIT",
  });

  const handleRegisterClick = async () => {
    setRegisterState({ type: "LOADING" });
    try {
      await borgeApi.post("sign-up", {
        email,
      });
      setRegisterState({ type: "SUCCESS", data: null });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setRegisterState({
          type: "ERROR",
          error: e.response?.data?.message ?? "Noe gikk galt, prøv igjen",
        });
      } else {
        setRegisterState({ type: "ERROR", error: "Noe gikk galt, prøv igjen" });
      }
    }
  };

  return (
    <ContentContainer ref={signUpRef}>
      <Container>
        <div>
          <h2>Høres dette ut som en tjeneste du vil ha?</h2>
          <p>Eller kanskje en tjeneste du ikke vil ha?</p>
          <p>
            I begge tilfeller er vi veldig interessert i å prate med deg, for å
            få laget den beste versjonen av denne tjenesten!
          </p>
        </div>
        <div
          css={css`
            margin-top: 1.5em;
          `}
        >
          <input
            css={css`
              background-color: transparent;
              border: 1px solid black;
              border-radius: 12px;
              padding: 16px;
            `}
            placeholder="Skriv inn din e-post"
            type="email"
            onChange={(e) => {
              if (!emailIsValid) {
                setEmailIsValid(validateEmail(email));
              }
              setEmail(e.target.value);
            }}
            onBlur={() => setEmailIsValid(validateEmail(email))}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleRegisterClick();
              }
            }}
          />
          {!emailIsValid && (
            <div
              css={css`
                color: #cc0000;
              `}
            >
              {email} er ikke en gyldig e-post addresse
            </div>
          )}
          <Spacer h={24} />
          {registerState.type === "SUCCESS" && <SignUpSuccess />}
          <Button
            disabled={registerState.type === "SUCCESS"}
            css={css`
              background-color: #fbf5eb;
              color: black;
            `}
            onClick={handleRegisterClick}
          >
            Jeg vil vite mer!
          </Button>
          {registerState.type === "ERROR" && (
            <ErrorAlert
              css={css`
                margin-top: 16px;
              `}
            >
              {registerState.error}
            </ErrorAlert>
          )}
        </div>
      </Container>
    </ContentContainer>
  );
};

const scale = keyframes`
  0% { 
    opacity: 0;
    transform: scale(0)
  }
  50% { 
    opacity: 0.8;
    transform: scale(1.4)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`;

const opacity = keyframes`
  0% { 
    opacity: 0;
  }
  50% { 
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
`;

const SignUpSuccess = () => (
  <Row
    align="center"
    css={css`
      margin-bottom: 16px;
    `}
  >
    <span
      css={css`
        animation: ${opacity} 1000ms ease-in-out;
      `}
    >
      Registrering velykket
    </span>
    <img
      src={confetti}
      alt="Konfetti"
      css={css`
        margin-left: 16px;
        width: 24px;
        height: 24px;
        animation: ${scale} 1000ms ease-in-out;
      `}
    />
  </Row>
);

const Container = styled.section`
  max-width: 900px;
  border-radius: 12px;
  margin: ${(props) => props.theme.spacers.s};
  padding: ${(props) => props.theme.spacers.m};
  background-color: #e3dfff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @media screen and (min-width: ${({ theme }) => theme.screenSize.m}px) {
    margin: ${(props) => props.theme.spacers.s} auto;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
  }
`;
