/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css, useTheme } from "@emotion/react";
import iterate from "./assets/iterate.gif";

export const Footer: FC = () => {
  const theme = useTheme();
  return (
    <div
      css={css`
        border-top: 2px solid black;
        margin-top: ${theme.spacers.l};
        margin-bottom: ${theme.spacers.l};
      `}
    >
      <div
        css={css`
          margin: ${theme.spacers.m};
          text-align: center;
        `}
      >
        <p>
          Vi er i tidlig utviklingsfase, om du lurer på noe, kontakt:{" "}
          <a
            css={css`
              font-weight: bold;
              &:hover {
                text-decoration: underline;
              }
            `}
            href="mailto:hei@borge.no"
          >
            hei@borge.no
          </a>
        </p>
        <p
          css={css`
            margin: ${theme.spacers.m};
          `}
        >
          Borge er en startup fra{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            css={css`
              text-decoration: underline;
            `}
            href="https://iterate.no"
          >
            Iterate
          </a>
        </p>
        <img
          src={iterate}
          alt="iterate"
          css={css`
            width: 90px;
          `}
        />
      </div>
    </div>
  );
};
