/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { ContentContainer } from "../../../components/Container";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import purpleLineLeft from "./assets/purpleLineLeft.svg";
import purpleLineLong from "./assets/purpleLineLong.svg";
import yourOverview from "./assets/yourOverview.svg";

export const Info: FC = () => {
  const theme = useTheme();
  const deviceSize = useDeviceSize();

  return (
    <div
      css={css`
        overflow-x: hidden;
      `}
    >
      <ContentContainer>
        <Container>
          <div
            css={css`
              padding: 0 32px;
              color: ${theme.colors.text.secondary};

              h2 {
                text-align: center;

                @media screen and (min-width: ${theme.screenSize.m}px) {
                  text-align: left;
                }
              }
            `}
          >
            <h2>Få full oversikt i én app!</h2>
            <p>
              Du vil hele tiden vite hvor mye den neste nedbetalingen er, hvor
              mye som er renter og hvor mye som er avdrag.
            </p>
            <p>
              I appen får du oversikt over lånet ditt, du kan endre kriterier og
              du kan avslutte og få tilbake ditt gamle boliglån når som helst.
            </p>
          </div>
          <div
            css={css`
              position: relative;
              height: 850px;
            `}
          >
            <img
              src={yourOverview}
              alt="Din oversikt"
              css={css`
                width: 361px;
                position: absolute;
                top: 40px;
                right: -110px;
                transform: rotate(-11deg);

                @media screen and (min-width: ${theme.screenSize.m}px) {
                  right: 0;
                }
              `}
            />
            <img
              src={deviceSize === "s" ? purpleLineLeft : purpleLineLong}
              alt=""
              css={css`
                position: absolute;
                z-index: 1;
                width: 320px;
                left: 0;
                bottom: 160px;

                @media screen and (min-width: ${theme.screenSize.m}px) {
                  width: initial;
                  left: initial;
                  transform: scale(0.8);
                  right: -1030px;
                  bottom: 240px;
                }
              `}
            />
          </div>
          <div
            css={css`
              padding: 0 32px;
              color: ${theme.colors.text.secondary};
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              grid-column: span 2;
              margin-bottom: ${theme.spacers.xl};
            `}
          >
            <p
              css={css`
                text-align: center;
                font-size: ${theme.fontSize.dynamic.l};
                line-height: 1.4;
                max-width: 660px;
              `}
            >
              Nå kan bruke de nye sparepengene dine på noe annet.
            </p>
          </div>
        </Container>
      </ContentContainer>
    </div>
  );
};

const Container = styled.div`
  h2 {
    font-size: ${(props) => props.theme.fontSize.dynamic.l};
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: 800;
  }
  @media screen and (min-width: ${(props) => props.theme.screenSize.m}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
