import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./pages/home/Home";
import { Global } from "./styles/Global";
import { theme } from "./styles/theme";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Global />
        <Home />
      </ThemeProvider>
    </QueryClientProvider>
  );
};
