/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { FC, ReactNode } from "react";
import { screenReaderOnly } from "../styles/styles";
import arrowDown from "./assets/arrow-down.svg";

export const Input: FC<{
  value: string;
  label: string;
  type: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  labelOnlyForScreenReaders?: boolean;
  unit?: string;
  css?: SerializedStyles;
}> = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  labelOnlyForScreenReaders,
  unit,
  onBlur,
  css: propCss,
}) => {
  return (
    <label
      css={[
        css`
          position: relative;
          font-size: 18px;
          display: flex;
          flex-direction: column;
        `,
        propCss,
      ]}
    >
      <span
        css={[
          css`
            margin-bottom: 16px;
          `,
          labelOnlyForScreenReaders && screenReaderOnly,
        ]}
      >
        {label}
      </span>
      <input
        type={type}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
        placeholder={placeholder}
        css={css`
          border: none;
          padding: 16px;
          padding-right: ${unit ? 32 : 16}px;
          background-color: white;
          border-radius: 8px;
          * {
            color: black;
          }
        `}
      />
      {unit && (
        <span
          css={css`
            position: absolute;
            right: 16px;
            bottom: 16px;
          `}
        >
          {unit}
        </span>
      )}
    </label>
  );
};

export const Select: FC<{
  value: string;
  onChange: (value: string) => void;
  label: string;
  children: ReactNode;
  labelOnlyForScreenReaders?: boolean;
  css?: SerializedStyles;
}> = ({
  value,
  onChange,
  label,
  children,
  labelOnlyForScreenReaders,
  css: propCss,
}) => {
  return (
    <label
      css={[
        css`
          position: relative;
          font-size: 18px;
          display: flex;
          flex-direction: column;
        `,
        propCss,
      ]}
    >
      <span
        css={[
          css`
            margin-bottom: 16px;
          `,
          labelOnlyForScreenReaders && screenReaderOnly,
        ]}
      >
        {label}
      </span>
      <select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        css={css`
          border: none;
          background-color: white;
          border-radius: 8px;
          padding: 16px;
          background: url(${arrowDown}) no-repeat right 10px bottom 50% white;
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */
          appearance: none;
        `}
      >
        {children}
      </select>
    </label>
  );
};
