/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from "@emotion/react";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { UseMutationResult } from "react-query";
import { animated, useSpring } from "react-spring";
import { ErrorAlert } from "../../../components/Alerts";
import arrowDown from "../../../components/assets/arrow-down.svg";
import { Column, Row } from "../../../components/Flex";
import { Spacer } from "../../../components/Spacer";
import {
  Bank,
  CurrentLoan,
  LoanDetailsResponse,
} from "../../../utils/useCalculateLoan";
import confetti from "./assets/confetti.svg";
import piggyBank from "./assets/piggyBank.svg";
import externalLink from "./assets/externalLink.svg";
import { Button } from "../../../components/Buttons";

export const Answer: FC<{
  loanDetails: UseMutationResult<
    LoanDetailsResponse,
    AxiosError<{ success: boolean; message: string }>,
    CurrentLoan,
    unknown
  >;

  onSignUpClick: () => void;
}> = ({ loanDetails, onSignUpClick }) => {
  const theme = useTheme();

  return (
    <Column
      css={css`
        margin-top: ${theme.spacers.l};
        width: 100%;
      `}
    >
      {loanDetails.isSuccess && (
        <AnswerWithData
          loanDetails={loanDetails.data}
          onSignUpClick={onSignUpClick}
        />
      )}
      {loanDetails.isError && (
        <ErrorAlert>
          {loanDetails.error.response?.data?.message ?? "Noe gikk galt"}
        </ErrorAlert>
      )}
      <Loader isLoading={loanDetails.isLoading} />
    </Column>
  );
};

const bounce = (y: number, scaleY: number) => keyframes`
  from {
      transform: translate3d(20px, 0, 0) scaleY(1);
  }
  to {
      transform: translate3d(20px, ${y}px, 0) scaleY(${scaleY});
  }
`;

const Loader: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const lineStyle = useSpring({
    transform: isLoading ? "scale(1)" : "scale(0)",
    from: {
      transform: "scale(1)",
    },
  });

  const pigStyle = useSpring({
    transform: isLoading ? "scale(0)" : "scale(1)",
    from: {
      transform: "scale(0)",
    },
  });

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        min-height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      `}
    >
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={css`
          top: 0;
          z-index: 1;
          position: absolute;
          animation: ${bounce(isLoading ? 31 : 60, isLoading ? 0.9 : 1)} 400ms;
          animation-direction: alternate;
          animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
          animation-fill-mode: forwards;
          animation-iteration-count: ${isLoading ? "infinite" : 1};
        `}
      >
        <ellipse cx="25" cy="25" rx="25" ry="25" fill="#DEDDDD" />
      </svg>
      <animated.svg
        width="107"
        height="50"
        viewBox="0 0 107 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={lineStyle}
      >
        <line
          x1="25"
          y1="25"
          x2="82"
          y2="25"
          stroke="#DA9267"
          strokeWidth="50"
          strokeLinecap="round"
        />
      </animated.svg>
      {!isLoading && (
        <animated.div
          css={css`
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
          `}
          style={pigStyle}
        >
          <img
            src={confetti}
            alt="confetti"
            css={css`
              position: absolute;
              top: 0;
              left: 30px;
              height: 78px;
              width: 78px;
            `}
          />
          <img
            src={piggyBank}
            alt="Sparegris"
            css={css`
              top: 0;
              left: 30px;
              width: 224px;
            `}
          />
        </animated.div>
      )}
    </div>
  );
};

const AnswerWithData: FC<{
  loanDetails: LoanDetailsResponse;
  onSignUpClick: () => void;
}> = ({ loanDetails, onSignUpClick }) => {
  const theme = useTheme();

  return (
    <>
      <Column flex={1}>
        <span>Dette kan du spare:</span>
        <Spacer h={24} />
        <span
          css={css`
            font-size: 56px;
            color: ${theme.colors.text.secondary};
          `}
        >
          <strong>{loanDetails.yearlySavings ?? "-"},-</strong> i året
        </span>
        <Spacer h={24} />
        <span
          css={css`
            color: ${theme.colors.text.secondary};
          `}
        >
          Du betaler{" "}
          <strong>{loanDetails.currentMonthlyPayment ?? "-"},-</strong> i mnd nå
        </span>
        <Spacer h={8} />
        <span
          css={css`
            color: ${theme.colors.text.secondary};
          `}
        >
          Du kan betale rundt{" "}
          <strong>{loanDetails.potentialMonthlyPayment ?? "-"},-</strong> i mnd
        </span>
      </Column>
      <MoreInfo loanDetails={loanDetails} />
      <Row>
        <Button onClick={onSignUpClick}>Jeg vil vite mer!</Button>
      </Row>
    </>
  );
};

const MoreInfo: FC<{ loanDetails: LoanDetailsResponse }> = ({
  loanDetails,
}) => {
  const [moreInfo, setMoreInfo] = useState(false);

  return (
    <>
      <button
        onClick={() => setMoreInfo((cs) => !cs)}
        css={css`
          margin-top: 24px;
          display: flex;
          background-color: transparent;
          border: none;
          padding: 0;
          cursor: pointer;

          img {
            width: 16px;
            margin: 2px 0 0 8px;
            transform: rotate(${moreInfo ? 180 : 0}deg);
            transition: transform 250ms ease-in-out;
          }
        `}
      >
        Mer info <img src={arrowDown} alt="" />
      </button>
      <div
        css={css`
          max-height: ${moreInfo ? 500 : 0}px;
          overflow-y: hidden;
          margin-top: 16px;
          transition: max-height 800ms ease-in-out;
        `}
      >
        {loanDetails.currentBank && (
          <BankInfo title="Nåværende bank" bank={loanDetails.currentBank} />
        )}
        <strong>Top 5 billigste banker</strong>
        {loanDetails.cheapestBanks?.map((bank, index) => (
          <BankInfo key={bank.name} bank={bank} />
        ))}
      </div>
    </>
  );
};
const BankInfo: FC<{ title?: string; bank: Bank }> = ({ title, bank }) => (
  <div
    css={css`
      margin-bottom: 16px;
    `}
  >
    {title && <strong>{title}</strong>}
    <Column
      css={css`
        margin-top: 8px;
      `}
    >
      <span>
        Bank:{" "}
        {bank.url ? (
          <a
            css={css`
              &:hover {
                text-decoration: underline;
              }
            `}
            href={bank.url}
            target="_blank"
            rel="noreferrer"
          >
            {bank.name}

            <img
              src={externalLink}
              alt=""
              css={css`
                margin-left: 8px;
                width: 11px;
                height: 11px;
              `}
            />
          </a>
        ) : (
          bank.name
        )}
      </span>
      <span>Lån: {bank.title}</span>
      <span>Rente: {bank.interest}</span>
    </Column>
  </div>
);
