import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { borgeApi } from "./api";

export const useGetBanksApi = (enabled = true) => {
  return useQuery<string[], AxiosError>(
    "tollStations",
    () => borgeApi("banks").then((res) => res.data),
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      enabled,
    }
  );
};
