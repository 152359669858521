/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, MutableRefObject } from "react";
import { ContentContainer } from "../../../components/Container";

export const HowItWorks: FC<{
  howItWorksRef: MutableRefObject<HTMLDivElement | null>;
}> = ({ howItWorksRef }) => {
  return (
    <ContentContainer ref={howItWorksRef}>
      <Title>
        <h2>Slik vil det fungere:</h2>
      </Title>
      <CardContainer>
        <Card title="1" text="Last ned appen og lag bruker" />
        <Card title="2" text="Sett kriterier for ditt boliglån" />
        <Card title="3" text="Få tilbud av oss, godkjenn, så er det ferdig!" />
      </CardContainer>
    </ContentContainer>
  );
};

const Title = styled.div`
  margin-top: ${(props) => props.theme.spacers.xl};
  text-align: center;
  h2 {
    font-size: ${(props) => props.theme.fontSize.dynamic.l};
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: bold;
  }
`;
const CardContainer = styled.section`
  padding-top: 24px;
  padding-bottom: 16px;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;

  div:first-of-type {
    margin-left: 30px;
  }
`;

const Card: FC<{ title: string; text: string }> = ({ title, text }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: relative;
        min-width: 234px;
        max-width: 250px;
        min-height: 158px;
        background-color: #da9267;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        margin-right: 8px;
        padding: 0 18px;
      `}
    >
      <span
        css={css`
          font-size: 48px;
          position: absolute;
          top: -24px;
          left: 34px;
          color: ${theme.colors.text.secondary};
        `}
      >
        {title}
      </span>
      <p
        css={css`
          text-align: center;
        `}
      >
        {text}
      </p>
    </div>
  );
};
