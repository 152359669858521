import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { borgeApi } from "./api";

export type Bank = {
  title: string;
  name: string;
  interest: string;
  monthlyPayment: string;
  url?: string;
};

export type CurrentLoan = {
  currentBank: string;
  loanAmount: string;
  askingPrice: string;
  loanTerms: string;
  age: string;
};

export type LoanDetailsResponse = {
  yearlySavings?: number;
  currentMonthlyPayment?: number;
  potentialMonthlyPayment?: number;
  potentialInterest?: number;
  cheapestBanks?: Bank[];
  currentBank?: Bank;
};

export const useGetLoanDetails = () => {
  return useMutation<
    LoanDetailsResponse,
    AxiosError<{ success: boolean; message: string }>,
    CurrentLoan,
    unknown
  >((values: CurrentLoan) =>
    borgeApi
      .post<LoanDetailsResponse>("loan-details", values)
      .then((res) => res.data)
  );
};
