/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Button } from "../../../components/Buttons";
import { ContentContainer } from "../../../components/Container";
import { Row } from "../../../components/Flex";
import { Spacer } from "../../../components/Spacer";
import greyDot from "./assets/greyDot.svg";
import orangeLine from "./assets/orangeLine.svg";
import purpleLine from "./assets/purpleLine.svg";

export const Hero: FC<{
  onHowMuchCanISaveClick: () => void;
  onHowItWorksClick: () => void;
}> = ({ onHowMuchCanISaveClick, onHowItWorksClick }) => {
  const theme = useTheme();
  return (
    <StyledHero>
      <ContentContainer
        css={css`
          z-index: 1;
          margin: ${theme.spacers.m};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 0 ${theme.spacers.xl} 0;
        `}
      >
        <Row
          justify="space-between"
          align="center"
          css={css`
            width: 100%;
            position: relative;
            margin-top: ${theme.spacers.m};
          `}
        >
          <Row as="span" align="center">
            <a
              href="/"
              css={css`
                font-size: ${theme.fontSize.l};
              `}
            >
              Borge
            </a>
            <span
              css={css`
                margin-left: ${theme.spacers.s};
                font-size: ${theme.fontSize.l};
                display: none;
                white-space: nowrap;
                color: #da9267;

                @media screen and (min-width: ${theme.screenSize.m}px) {
                  display: block;
                }
              `}
            >
              Ha det beste boliglånet, hele tiden.
            </span>
          </Row>
          <Button onClick={onHowItWorksClick}>Hvordan fungerer det?</Button>
        </Row>

        <Spacer h={100} />
        <h1
          css={css`
            text-align: center;
            max-width: 1080px;
          `}
        >
          Sørg for at du har det beste boliglånet
        </h1>
        <p
          css={css`
            margin: ${theme.spacers.m};
            text-align: center;
            max-width: 65pc;
          `}
        >
          Vi flytter boliglånet ditt, slik at du får de beste betingelsene på
          ditt lån, til en hver tid.
        </p>
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <Button onClick={onHowMuchCanISaveClick}>
            Se hvor mye du kan spare
          </Button>
        </div>
      </ContentContainer>
      <SvgWrapper>
        <SvgImage
          src={purpleLine}
          top={150}
          right={0}
          css={css`
            width: 280px;
            mix-blend-mode: multiply;

            @media screen and (min-width: ${theme.screenSize.m}px) {
              right: -${theme.spacers.s};
            }
          `}
        />
        <SvgImage
          src={greyDot}
          top={400}
          right={0}
          css={css`
            width: 150px;
            display: none;

            @media screen and (min-width: ${theme.screenSize.m}px) {
              height: 160px;
              width: 180px;
              right: initial;
              left: 60px;
              top: 130px;
              display: block;
              mix-blend-mode: multiply;
            }
          `}
        />
        <SvgImage
          src={orangeLine}
          top={530}
          left={0}
          css={css`
            width: 140px;
            top: -120px;
            z-index: -1;

            @media screen and (min-width: ${theme.screenSize.m}px) {
              height: 150px;
              left: -16px;
              top: 340px;
              mix-blend-mode: multiply;
            }
          `}
        />
      </SvgWrapper>
    </StyledHero>
  );
};

const StyledHero = styled.div`
  width: 100%;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondary};

  @media screen and (min-width: ${(props) => props.theme.screenSize.m}px) {
    margin: ${(props) => props.theme.spacers.s};
    background-color: ${(props) => props.theme.colors.background.secondary};
    border-radius: 24px;
  }
`;

const SvgWrapper = styled.div`
  z-index: 0;
  position: absolute;
  top: ${(props) => props.theme.spacers.m};
  left: 0;
  right: 0;
`;

const SvgImage = styled.img<{
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  position: absolute;
  top: ${(props) => (props.top !== undefined ? `${props.top}px` : "initial")};
  right: ${(props) =>
    props.right !== undefined ? `${props.right}px` : "initial"};
  bottom: ${(props) =>
    props.bottom !== undefined ? `${props.bottom}px` : "initial"};
  left: ${(props) =>
    props.left !== undefined ? `${props.left}px` : "initial"};
`;
