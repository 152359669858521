import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const screenReaderOnly = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const buttonReset = css`
  background: none;
  color: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  cursor: pointer;
  text-align: left;
`;

export const SmallScreensOnly = styled.div`
  @media screen and (min-width: ${(props) => props.theme.screenSize.m}px) {
    display: none;
  }
`;

export const MediumAndBiggerScreensOnly = styled.div`
  @media screen and (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: none;
  }
`;
