export type Colors = {
  text: {
    primary: string;
    secondary: string;
    light: string;
    dark: string;
  };
  action: {
    primary: string;
    secondary: string;
  };
  button: {
    primary: string;
  };
  background: {
    primary: string;
    secondary: string;
  };
  gray: string;
  black: string;
};

const baseColors = {
  purple: "#a192ff",
  darkBlue: "#1d175d",
  grey: "#797979",
  black: "#0f0f0f",
  white: "#ffffff",
};

const colors: Colors = {
  text: {
    primary: baseColors.black,
    secondary: baseColors.darkBlue,
    light: baseColors.white,
    dark: baseColors.black,
  },
  action: {
    primary: baseColors.darkBlue,
    secondary: baseColors.grey,
  },
  button: {
    primary: baseColors.darkBlue,
  },
  background: {
    primary: "#fffbf5",
    secondary: "#fbf5eb",
  },
  gray: "#f1f1f1",
  black: baseColors.black,
};

export type Spacers = {
  xs: "0.5rem";
  s: "1rem";
  m: "2rem";
  l: "3rem";
  xl: "4rem";
};

const spacers: Spacers = {
  xs: "0.5rem",
  s: "1rem",
  m: "2rem",
  l: "3rem",
  xl: "4rem",
};

export type FontSize = {
  base: "1rem";
  xs: "0.5rem";
  s: "0.8rem";
  m: "1.5rem";
  l: "2rem";
  xl: "3rem";
  dynamic: {
    s: "clamp(0.8rem, 2vw, 1.6rem)";
    m: "clamp(1.5rem, 1.5vw, 2rem)";
    l: "clamp(2rem, 2.6vw, 3.5rem)";
    xl: "clamp(3rem, 7.5vw, 5.5rem)";
  };
};

const fontSize: FontSize = {
  base: "1rem",
  xs: "0.5rem",
  s: "0.8rem",
  m: "1.5rem",
  l: "2rem",
  xl: "3rem",
  dynamic: {
    s: "clamp(0.8rem, 2vw, 1.6rem)",
    m: "clamp(1.5rem, 1.5vw, 2rem)",
    l: "clamp(2rem, 2.6vw, 3.5rem)",
    xl: "clamp(3rem, 7.5vw, 5.5rem)",
  },
};

export type ScreenSize = {
  m: 700;
  l: 992;
};

export const screenSize: ScreenSize = {
  m: 700,
  l: 992,
};

export const theme = {
  colors,
  spacers,
  fontSize,
  screenSize,
};
