import axios from "axios";

export const apiRoot = (() => {
  if (window.location.hostname === "localhost") {
    return process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3001";
  }
  if (window.location.hostname.endsWith(".test.iterate.no")) {
    return "https://borge-api.test.iterate.no";
  }
  return "https://api.borge.no";
})();

export const borgeApi = axios.create({
  baseURL: apiRoot,
});
