/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { Global as GlobalEmotion, css, useTheme } from "@emotion/react";
import { normalize } from "polished";

export const Global: FC = () => {
  const theme = useTheme();

  return (
    <GlobalEmotion
      styles={css`
        /* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap"); */
        @font-face {
          font-family: "UncutSans";
          font-weight: 300;
          font-style: normal;
          font-display: swap;
          src: url("fonts/UncutSans-Regular.woff") format("woff"),
            url("fontsUncutSans-Regular.woff2") format("woff2");
        }
        @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");

        ${normalize()}

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        html,
        body {
          padding: 0;
          margin: 0;
          background-color: ${theme.colors.background.primary};
          color: ${theme.colors.text.secondary};
        }

        html {
          box-sizing: border-box;
          font-size: 100%;
          scroll-behavior: smooth;
        }

        body {
          /* font-family: "Inter", sans-serif; */
          font-family: "UncutSans", sans-serif;
          font-feature-settings: "ss02";
          font-size: ${theme.fontSize.base};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        a {
          color: inherit;
          text-decoration: none;
        }

        img {
          width: 100%;
          height: auto;
        }
        h1,
        h2,
        h3,
        h4 {
          font-family: "Space Grotesk", sans-serif;
        }

        h1 {
          font-size: ${theme.fontSize.dynamic.xl};
          margin: 0;
          font-weight: 800;
        }

        h2 {
          font-weight: 400;
          font-size: ${theme.fontSize.m};
        }

        p {
          font-size: 1.2rem;
          line-height: 1.6;
        }
      `}
    />
  );
};
