/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactSelect from "react-select";
import arrowDown from "../../../components/assets/arrow-down.svg";
import { Button } from "../../../components/Buttons";
import { ContentContainer } from "../../../components/Container";
import { Column } from "../../../components/Flex";
import { Input, Select } from "../../../components/Inputs";
import {
  MediumAndBiggerScreensOnly,
  SmallScreensOnly,
} from "../../../styles/styles";
import { thousandSeparatedString } from "../../../utils/number";
import { useGetLoanDetails } from "../../../utils/useCalculateLoan";
import { useGetBanksApi } from "../../../utils/useGetBanks";
import finansportalen from "./assets/finansportalen.svg";
import { Answer } from "./Answer";
import { ErrorAlert } from "../../../components/Alerts";

export const Calculator: FC<{
  calculatorRef: MutableRefObject<HTMLDivElement | null>;
  onSignUpClick: () => void;
}> = ({ calculatorRef, onSignUpClick }) => {
  const banks = useGetBanksApi();
  const loanDetails = useGetLoanDetails();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [currentBank, setCurrentBank] = useState<string>("");
  const [loanAmount, setLoanAmount] = useState<string>("");
  const [askingPrice, setAskingPrice] = useState<string>("");
  const [loanTerms, setLoanTerms] = useState("");
  const [age, setAge] = useState<string>("");
  const [loanAmountInvalid, setLoanAmountInvalid] = useState<boolean>(false);

  const handleCalculateLoanClick = () => {
    loanDetails.mutate({
      currentBank: currentBank || "",
      loanAmount: loanAmount.replace(/\s+/g, ""),
      askingPrice: askingPrice.replace(/\s+/g, ""),
      loanTerms: loanTerms,
      age: age,
    });
  };

  const handleCheckLoanAmountValid = useCallback(() => {
    if (Boolean(askingPrice) && Boolean(loanAmount)) {
      const percentage =
        (Number(askingPrice.replace(/\s+/g, "")) /
          Number(loanAmount.replace(/\s+/g, ""))) *
        100;

      console.log("percentage", percentage);
      console.log("percentage < 85", percentage < 85);
      console.log("percentage > 85", percentage > 85);

      setLoanAmountInvalid(percentage < 85);
    }
  }, [askingPrice, loanAmount]);

  useEffect(() => {
    console.log("loanAmountValid", loanAmountInvalid);
  }, [loanAmountInvalid]);

  useEffect(() => {
    if (!loanDetails.isIdle && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [loanDetails.isIdle]);

  return (
    <ContentContainer>
      <Container>
        <SmallScreensOnly>
          <h2>Hvor mye kan jeg spare?</h2>
        </SmallScreensOnly>
        <CalculatorWrapper>
          <MediumAndBiggerScreensOnly>
            <Column
              css={css`
                margin-right: ${theme.spacers.l};
              `}
            >
              <h2>Små endringer, kan utgjøre store forskjeller</h2>
              <p>
                Med Borge, så vil du hver måned få maks ut av boliglånet ditt.
                Vi flytter lånet ditt mellom banker, basert på dine kriterier.
              </p>
            </Column>
          </MediumAndBiggerScreensOnly>
          <CalculatorContainer ref={calculatorRef}>
            <h2>Legg inn ditt nåværende lån</h2>
            <div className="something">
              <InputWrapper
                css={css`
                  grid-area: currentBank;
                `}
              >
                <ReactSelect
                  onChange={(value) => setCurrentBank(value?.value ?? "")}
                  isLoading={banks.isFetching}
                  placeholder="Velg bank"
                  options={
                    banks.data?.map((bank) => ({ value: bank, label: bank })) ??
                    []
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      backgroundColor: "white",
                      borderRadius: 8,
                      padding: 16,
                      background: `url(${arrowDown}) no-repeat right 10px bottom 50% white`,
                      appearance: "none",
                      fontSize: 18,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      ...css`
                        svg {
                          display: none;
                        }
                      `,
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                    }),
                    loadingIndicator: (provided) => ({
                      ...provided,
                      position: "absolute",
                      top: 18,
                      right: 24,
                    }),
                  }}
                />
              </InputWrapper>
              <InputWrapper
                css={css`
                  grid-area: loanAmount;
                `}
              >
                <Input
                  type="tel"
                  value={loanAmount}
                  onChange={(value) =>
                    setLoanAmount(thousandSeparatedString(value))
                  }
                  label="Lånebeløp"
                  placeholder="Skriv inn ditt lån"
                  unit="kr"
                  onBlur={handleCheckLoanAmountValid}
                />
              </InputWrapper>
              <InputWrapper
                css={css`
                  grid-area: askingPrice;
                `}
              >
                <Input
                  type="tel"
                  value={askingPrice}
                  onChange={(value) =>
                    setAskingPrice(thousandSeparatedString(value))
                  }
                  label="Boligens verdi"
                  placeholder="Skriv inn verdi"
                  unit="kr"
                  onBlur={handleCheckLoanAmountValid}
                />
              </InputWrapper>
              <InputWrapper
                css={css`
                  grid-area: loanTerms;
                `}
              >
                <Select
                  value={loanTerms}
                  onChange={(value) => setLoanTerms(value)}
                  label="Nedbetalingstid"
                  css={css`
                    margin-right: 16px;
                  `}
                >
                  <option value="" disabled>
                    Velg
                  </option>
                  <option value="30">30</option>
                  <option value="25">25</option>
                  <option value="20">20</option>
                  <option value="15">15</option>
                  <option value="10">10</option>
                  <option value="5">5</option>
                </Select>
              </InputWrapper>
              <InputWrapper
                css={css`
                  grid-area: age;
                `}
              >
                <Select
                  value={age}
                  onChange={(value) => setAge(value)}
                  label="Din alder"
                >
                  <option value="" disabled>
                    Velg
                  </option>
                  <option value="75">75+</option>
                  <option value="71">71-75</option>
                  <option value="66">66-70</option>
                  <option value="61">61-65</option>
                  <option value="56">56-60</option>
                  <option value="51">51-55</option>
                  <option value="46">46-50</option>
                  <option value="41">41-45</option>
                  <option value="36">36-40</option>
                  <option value="31">31-35</option>
                  <option value="26">26-30</option>
                  <option value="18">18-25</option>
                </Select>
              </InputWrapper>
            </div>
            <div ref={ref} />
            {loanAmountInvalid && (
              <ErrorAlert>
                Lånebeløpet kan ikke være større enn 85% av boligens verdi
              </ErrorAlert>
            )}
            <Button
              disabled={
                !currentBank ||
                !loanAmount ||
                !askingPrice ||
                !loanTerms ||
                !age
              }
              onClick={handleCalculateLoanClick}
            >
              Se hvor mye du kan spare
            </Button>
            {!loanDetails.isIdle && (
              <Answer loanDetails={loanDetails} onSignUpClick={onSignUpClick} />
            )}
            {loanDetails.data && (
              <p
                css={css`
                  margin-top: ${theme.spacers.xl};
                  text-align: center;
                `}
              >
                Vi kan ikke flytte lånet for deg enda, vi er helt i starten. Har
                du lyst å snakke med oss, eller få vite mer, ta kontakt!
              </p>
            )}
            <p
              css={css`
                margin-top: ${loanDetails.data
                  ? theme.spacers.m
                  : theme.spacers.xl};
                text-align: center;
              `}
            >
              Utregningen er gjort med tall fra finansportalen
            </p>
            <img
              src={finansportalen}
              alt="Finansportalen"
              css={css`
                margin-top: ${theme.spacers.xs};
                max-width: 213px;
              `}
            />
          </CalculatorContainer>
        </CalculatorWrapper>
      </Container>
    </ContentContainer>
  );
};

const Container = styled.section`
  h2 {
    font-size: ${(props) => props.theme.fontSize.dynamic.l};
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: 700;
  }

  h2 + p {
    margin-top: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${(props) => props.theme.spacers.l};
  margin-right: ${(props) => props.theme.spacers.xs};
  margin-left: ${(props) => props.theme.spacers.xs};
`;

const CalculatorWrapper = styled.div`
  @media screen and (min-width: ${(props) => props.theme.screenSize.m}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const CalculatorContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background.secondary};
  padding: ${(props) => props.theme.spacers.m};
  padding-top: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .something {
    width: 100%;
    display: grid;
    grid-template-areas:
      "currentBank currentBank"
      "loanAmount loanAmount"
      "askingPrice askingPrice"
      "loanTerms age";
    grid-template-rows: repeat(4, auto);
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    grid-column-gap: 24px;
    margin-bottom: ${(props) => props.theme.spacers.m};
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: nowrap;

  * {
    flex-grow: 1;
  }
`;
