import styled from "@emotion/styled";

type SpacerType = {
  w?: number;
  h?: number;
};

export const Spacer = styled.div<SpacerType>`
  width: ${(props) => props.w ?? 0}px;
  height: ${(props) => props.h ?? 0}px;
`;
